// Enable offer click functionality
$(document).ready(function () {
    $(document).on('click', '.clickable', function () {
        window.open($(this).attr('data-href'));
    });

    $(document).on('click', '.clickable-same-tab', function () {
        window.location.href = $(this).attr('data-href');
    });
});
